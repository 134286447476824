import { tipoDeAcaoAtom } from './TipoDeAcaoState';
import { useBaseActions } from '../base.actions';
import { useBaseRootActions } from '../baseRoot.actions';

function useTipoDeAcaoActions() {
  const baseActions = useBaseRootActions('configTipoAcoes', tipoDeAcaoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useTipoDeAcaoActions };
