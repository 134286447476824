import { useParams } from "react-router-dom";
import { useComandaActions } from "../../../../states/comandas/comanda.actions";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IComanda, IStatus } from "../../../../commons/types/crm";
import { FormControl, Grid, List, ListItem, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import ReuniaoComponent from "./Components/Reunioes";
import HistoricoComponent from "./Components/Historico";
import { ButtonStato } from "../../../../commons/styleds/buttons";
import { useStatusActions } from "../../../../states/comandas/status/Status.actions";
import { SelectStato } from "../../../../commons/styleds/selects";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { comandaAtom } from "../../../../states/comandas/ComandaState";
import { useRecoilState } from "recoil";

export interface IParams {
  id?: string;
}

const ProgramaAtual: React.FC = () => {
  const { id } = useParams<IParams>();
  const statusActions = useStatusActions();
  const comandaActions = useComandaActions();
  const [comandaState, setComandaState] = useRecoilState(comandaAtom);
  const [loading, setLoading] = useState(true);
  const [comanda, setComanda] = useState<IComanda>();
  const [status, setStatus] = useState<IStatus[]>([]);
  const [statusSelected, setStatusSelected] = useState<IStatus>();
  const [dataInicio, setDataInicio] = useState<Dayjs>();
  const [dataFim, setDataFim] = useState<Dayjs>();
  const [dataExtensao, setDataExtensao] = useState<Dayjs>();
  const [idAssessorado, setIdAssessorado] = useState(0);

  useEffect(() => {
    carregarStatus()
      .then(() => carregarComanda());
  }, []);

  const carregarStatus = async () => {
    statusActions
      .list()
      .then((resp) => setStatus(resp))
      .catch((err) => toast.warn(err));
  }

  const carregarComanda = () => {
    setLoading(true);
    comandaActions
      .get(Number(id))
      .then((resp) => setDadosPorComanda(resp))
      .catch((err) => toast.warn(err))
      .finally(() => setLoading(false));
  }

  const setDadosPorComanda = (data: IComanda) => {
    setIdAssessorado(data.contato?.id ?? 0);
    setComanda(data);
    setStatusSelected(data.status);
    setDataInicio(dayjs(data.dataInicio));
    setDataFim(dayjs(data.dataFim));
    setDataExtensao(dayjs(data.dataExtensao));
  }

  const salvarComanda = async () => {
    setLoading(true);

    const data = {
      idStatus: statusSelected?.id,
      dataExtensao: dataExtensao ? dataExtensao.toDate() : null,
      dataInicio: dataInicio ? dataInicio.toDate() : null,
      dataFim: dataFim ? dataFim.toDate() : null
    };

    try {
      await comandaActions.patch(Number(id), data);
      toast.success('Comanda atualizada');
    } catch (error) {
      console.error(error);
      toast.error('Falha ao atualizar comanda');
    } finally {
      setLoading(false);
    }
  };

  const changeStatus = (e: any) => {
    const value = e.target.value;
    const st = status.find(s => s.id === value);
    setStatusSelected(st);
  }

  return (
    <Grid container rowSpacing={4} columnSpacing={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Assessorado:</Typography>
        <Typography variant="body1">{comanda?.contato?.nome}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Modelo Comercial:</Typography>
        <Typography variant="body1">{comanda?.modeloComercial}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Projeto Confidencial:</Typography>
        <Typography variant="body1">
          {comanda?.projetoConfidencial ? "Sim" : "Não"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Data Início:</Typography>
        <FormControl
          required
          variant='outlined'
          sx={{ marginTop: '10px' }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              clearButtonLabel: 'Empty',
              todayButtonLabel: 'Now',
            }}
          >
            <DesktopDatePicker
              value={dataInicio}
              label='Data Início'
              format='DD/MM/YYYY'
              timezone={'UTC'}
              defaultValue={dataInicio}
              disabled
              onChange={(value: any) => {
                setDataInicio(value);
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Data Fim:</Typography>
        <FormControl
          required
          variant='outlined'
          sx={{ marginTop: '10px' }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              clearButtonLabel: 'Empty',
              todayButtonLabel: 'Now',
            }}
          >
            <DesktopDatePicker
              value={dataFim}
              label='Data Fim'
              format='DD/MM/YYYY'
              timezone={'UTC'}
              defaultValue={dataFim}
              disabled
              onChange={(value: any) => {
                setDataFim(value);
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Plano:</Typography>
        <Typography variant="body1">{comanda?.plano}</Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">BUs</Typography>
        <Typography variant="body1">{comanda?.bUs}</Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Data Extensão:</Typography>
        <FormControl
          required
          variant='outlined'
          sx={{ marginTop: '10px' }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              clearButtonLabel: 'Empty',
              todayButtonLabel: 'Now',
            }}
          >
            <DesktopDatePicker
              value={dataExtensao}
              label='Data Extensão'
              format='DD/MM/YYYY'
              timezone={'UTC'}
              defaultValue={dataExtensao}
              onChange={(value: any) => {
                setDataExtensao(value);
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Status</Typography>
        <SelectStato
          value={statusSelected?.id ?? 0}
          onChange={changeStatus}
          required
          label='Status'
          sx={{ marginTop: '10px', minWidth: ' 180px' }}
        >
          {status.map((statu: IStatus, index: number) => (
            <MenuItem value={statu.id} key={index}>
              {statu.nome}
            </MenuItem>
          ))}
        </SelectStato>

      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Etapas:</Typography>
        <List>
          {comanda?.etapas?.map((etapa, index) => (
            <ListItem key={index}>{etapa.nome}</ListItem>
          ))}
        </List>
      </Grid>

      <Grid item xs={12}>
        <ButtonStato variant="contained" onClick={salvarComanda} disabled={loading}>
          Validação
        </ButtonStato>

      </Grid>

      <Grid item xs={12}>
        <ReuniaoComponent
          idComanda={Number(id)}
          etapas={comanda?.etapas ?? []}
          comandaEtapas={comanda?.comandaEtapaReunioes ?? []}
          comanda={comanda}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <HistoricoComponent comandaId={Number(id)} loading={loading} />
      </Grid>
    </Grid>
  );
};

export default ProgramaAtual;
