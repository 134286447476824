import { useBaseRootActions } from '../../baseRoot.actions';
import { idiomaAtom } from './IdiomaState';

function useIdiomaConfigActions() {
  const baseActions = useBaseRootActions('configIdiomas', idiomaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useIdiomaConfigActions };
