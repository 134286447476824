import { IconButton, Menu, MenuItem } from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import { useState } from "react";
import { IReuniao, StatusReuniaoEnum } from "../../commons/types/crm";
import { history } from "../../helpers/history";

export const AcoesColumn = ({
  row,
  setOpen,
  setReuniao,
}: {
  row: IReuniao;
  setOpen: Function;
  setReuniao: Function;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const disabled =
    row.status !== StatusReuniaoEnum.CANCELADA &&
    row.status !== StatusReuniaoEnum.AGENDADA;
  const disableRevisar = row.status !== StatusReuniaoEnum.AGENDADA;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRevisar = () => {
    history.push(`/assessorados/${row.idComanda}?reuniao=${row.id}`);
  };

  const handleAgendar = () => {
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="mais ações"
        disabled={disabled}
        onClick={handleClick}
      >
        <GridMoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleRevisar} disabled={disableRevisar}>
          Revisar
        </MenuItem>
        {/* <MenuItem onClick={handleAgendar}>Agendar</MenuItem> */}
      </Menu>
    </>
  );
};
