import { useBaseRootActions } from '../../baseRoot.actions';
import { setorAtom } from './SetorState';

function useSetorActions() {
  const baseActions = useBaseRootActions('configSetores', setorAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useSetorActions };
