import {
  FormControl,
  Grid,
  Drawer,
  Typography,
  LinearProgress,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { NumericFormat } from "react-number-format";
import {
  IComandaEtapaReunioes,
  IEtapa,
} from "../../../../../commons/types/crm";
import { IAuth } from "../../../../../commons/types/usuatios";
import { ButtonStato } from "../../../../../commons/styleds/buttons";
import { IconDrawer } from "../../../../../commons/styleds/icons";
import { SelectStato } from "../../../../../commons/styleds/selects";
import { TextFieldStato } from "../../../../../commons/styleds/textFields";
import { GridItemCadastroBtnSalvar } from "../../../../../commons/styleds/grids";

interface IDrawerReunioes {
  open: boolean;
  setOpen: Function;
  editando: boolean;
  setEditando: Function;
  reuniao: IComandaEtapaReunioes;
  setReuniao: Function;
  etapasSelect: IEtapa[];
  idComanda: number;
  loadingSave: boolean;
  consultores: IAuth[];
  adicionarNaLista: any;
  revisarReuniao: Function;
}

const DrawerReunioes: React.FC<IDrawerReunioes> = ({
  open,
  setOpen,
  editando,
  setEditando,
  reuniao,
  setReuniao,
  etapasSelect,
  idComanda,
  loadingSave,
  consultores,
  adicionarNaLista,
  revisarReuniao,
}) => {
  const closeDrawer = () => {
    setEditando(false);
    setOpen(false);
  };

  return (
    <Drawer
      style={{ minWidth: "250px" }}
      anchor={"right"}
      open={open}
      onClose={() => closeDrawer()}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{ padding: "90px 30px", maxWidth: "500px", width: "auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={2} md={2}>
            <IconDrawer onClick={() => closeDrawer()}>close</IconDrawer>
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography variant="h5" sx={{ paddingBottom: "20px" }}>
              {editando ? "Ações reunião" : "Agendar reunião"}
            </Typography>
          </Grid>
        </Grid>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Etapas</InputLabel>
          <SelectStato
            required
            label="Etapas"
            disabled={editando}
            value={reuniao.tbEtapaId}
            onChange={(event: any) => {
              setReuniao({
                ...reuniao,
                tbEtapaId: event.target.value,
                comandaId: idComanda!,
              });
            }}
          >
            {etapasSelect?.map((etapa: IEtapa, index: number) => {
              return (
                <MenuItem value={etapa.id} key={index}>
                  {etapa.nome}
                </MenuItem>
              );
            })}
          </SelectStato>
        </FormControl>

        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: "20px" }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              clearButtonLabel: "Empty",
              todayButtonLabel: "Now",
            }}
          >
            <DesktopDatePicker
              value={reuniao.dtPrevista}
              label="Data Prevista"
              format="DD/MM/YYYY"
              timezone={"UTC"}
              defaultValue={reuniao.dtPrevista}
              onChange={(value: any) => {
                setReuniao({ ...reuniao, dtPrevista: value });
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: "20px" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                "MobileTimePicker",
                "MobileTimePicker",
                "MobileTimePicker",
              ]}
            >
              <DemoItem label={"Hora Inicio"}>
                <TimePicker
                  views={["hours", "minutes"]}
                  format="HH:mm"
                  timezone={"UTC"}
                  ampm={false}
                  ampmInClock={false}
                  value={reuniao.dtRealizadaInicio}
                  onChange={(value: any) => {
                    setReuniao({ ...reuniao, dtRealizadaInicio: value });
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </FormControl>

        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: "20px" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                "MobileTimePicker",
                "MobileTimePicker",
                "MobileTimePicker",
              ]}
            >
              <DemoItem label={"Hora Fim"}>
                <TimePicker
                  views={["hours", "minutes"]}
                  format="HH:mm"
                  timezone={"UTC"}
                  ampm={false}
                  value={reuniao.dtRealizadaFim}
                  ampmInClock={false}
                  onChange={(value: any) => {
                    setReuniao({ ...reuniao, dtRealizadaFim: value });
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </FormControl>

        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: "20px" }}
        >
          <InputLabel id="demo-simple-select-label">Consultores</InputLabel>
          <SelectStato
            required
            label="Consultores"
            value={reuniao.idConsultorResponsavel}
            onChange={(event: any) => {
              setReuniao({
                ...reuniao,
                idConsultorResponsavel: event.target.value,
                valorHora: consultores.find((f) => f.id === event.target.value)
                  ?.valorHoraB2C,
              });
            }}
          >
            {consultores?.map((consultor: IAuth, index: number) => {
              return (
                <MenuItem value={consultor.id} key={index}>
                  {consultor.nome}
                </MenuItem>
              );
            })}
          </SelectStato>
        </FormControl>

        {
          /*<FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: "20px" }}
        >
          <NumericFormat
            value={reuniao?.valorHora}
            onValueChange={(e) =>
              setReuniao({ ...reuniao, valorHora: parseFloat(e.value!) })
            }
            prefix="R$"
            thousandSeparator="."
            decimalSeparator=","
            label="Valor Hora"
            customInput={TextFieldStato}
            valueIsNumericString
            allowLeadingZeros={false}
            decimalScale={2}
            fixedDecimalScale
          />
        </FormControl>
*/}
        {loadingSave && <LinearProgress />}

        <GridItemCadastroBtnSalvar item xs={11} md={11}>
          <ButtonStato variant="contained" onClick={adicionarNaLista}>
            Agendar
          </ButtonStato>
          {editando && (
            <>
              <ButtonStato
                variant="contained"
                onClick={() => revisarReuniao("concluir")}
                color="success"
              >
                Concluir
              </ButtonStato>
              <ButtonStato
                variant="contained"
                onClick={() => revisarReuniao("noShow")}
                color="error"
              >
                No Show
              </ButtonStato>
            </>
          )}
        </GridItemCadastroBtnSalvar>
      </Grid>
    </Drawer>
  );
};

export default DrawerReunioes;
