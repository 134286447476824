import {
  FormControl,
  Grid,
  Drawer,
  Typography,
  LinearProgress,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { IconDrawer } from "../../../commons/styleds/icons";
import { GridItemCadastroBtnSalvar } from "../../../commons/styleds/grids";
import { ButtonStato } from "../../../commons/styleds/buttons";
import { IReuniao } from "../../../commons/types/crm";
import { useState } from "react";
import dayjs from "dayjs";
import { useReuniaoActions } from "../../../states/reunioes/reuniao.actions";
import { toast } from "react-toastify";

export interface IDrawerConcluir {
  reuniao?: IReuniao;
  setReuniao?: Function;
  open: boolean;
  setOpen: Function;
  loadingSave: boolean;
  setLoadingSave: Function;
}

const DrawerConcluir: React.FC<IDrawerConcluir> = ({
  reuniao,
  open,
  setOpen,
  loadingSave,
  setLoadingSave,
}) => {
  const [dataRealizada, setDataRealizada] = useState(
    dayjs.tz(reuniao?.dtPrevista, "utc"),
  );
  const [dataRealizadaInicio, setDataRealizadaInicio] = useState(
    dayjs.tz(reuniao?.dtRealizadaInicio, "utc"),
  );
  const [dataRealizadaFim, setDataRealizadaFim] = useState(
    dayjs.tz(reuniao?.dtRealizadaFim, "utc"),
  );
  const reuniaoActions = useReuniaoActions();

  const closeDrawer = () => {
    setLoadingSave(false);
    setOpen(false);
  };

  const concluirRuniao = () => {
    setLoadingSave(true);
    const dataInicio = new Date(dataRealizada.toDate());
    dataInicio.setHours(dataRealizadaInicio.hour());
    dataInicio.setMinutes(dataRealizadaInicio.minute());

    const dataFim = new Date(dataRealizada.toDate());
    dataFim.setHours(dataRealizadaFim.hour());
    dataFim.setMinutes(dataRealizadaFim.minute());

    reuniaoActions
      .patch(reuniao?.id!, { id: reuniao?.id, dataInicio, dataFim })
      .then(() => toast.success("Conclusão solicitada com sucesso."))
      .catch((e) => toast.error(e ?? "Falha ao concluir reunião."))
      .finally(() => closeDrawer());
  };

  return (
    <Drawer
      style={{ minWidth: "250px" }}
      anchor={"right"}
      open={open}
      onClose={() => closeDrawer()}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{ padding: "90px 30px", maxWidth: "500px", width: "auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={2} md={2}>
            <IconDrawer onClick={() => closeDrawer()}>close</IconDrawer>
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography variant="h5" sx={{ paddingBottom: "20px" }}>
              Concluir Reunião
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">Assessorado:</Typography>
          {reuniao?.nomeAssessorado}
        </Grid>

        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: "20px" }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              clearButtonLabel: "Empty",
              todayButtonLabel: "Now",
            }}
          >
            <DesktopDatePicker
              value={dataRealizada}
              label="Data Realizada"
              format="DD/MM/YYYY"
              timezone={"UTC"}
              defaultValue={dataRealizada}
              onChange={(value: any) => {
                setDataRealizada(value);
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: "20px" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                "MobileTimePicker",
                "MobileTimePicker",
                "MobileTimePicker",
              ]}
            >
              <DemoItem label={"Hora Inicio"}>
                <TimePicker
                  views={["hours", "minutes"]}
                  format="HH:mm"
                  timezone={"UTC"}
                  ampm={false}
                  ampmInClock={false}
                  value={dataRealizadaInicio}
                  onChange={(value: any) => {
                    setDataRealizadaInicio(value);
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </FormControl>

        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: "20px" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                "MobileTimePicker",
                "MobileTimePicker",
                "MobileTimePicker",
              ]}
            >
              <DemoItem label={"Hora Fim"}>
                <TimePicker
                  views={["hours", "minutes"]}
                  format="HH:mm"
                  timezone={"UTC"}
                  ampm={false}
                  value={dataRealizadaFim}
                  ampmInClock={false}
                  onChange={(value: any) => {
                    setDataRealizadaFim(value);
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </FormControl>

        <Grid item>{loadingSave && <LinearProgress />}</Grid>

        <GridItemCadastroBtnSalvar item xs={11} md={11}>
          <ButtonStato
            variant="contained"
            onClick={() => {
              concluirRuniao();
            }}
          >
            Concluir
          </ButtonStato>
        </GridItemCadastroBtnSalvar>
      </Grid>
    </Drawer>
  );
};

export default DrawerConcluir;
