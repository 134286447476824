import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { tableCard } from './tableCard';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IReuniao } from '../../commons/types/crm';

export const cardReuniao = (Icon: any, titulo: string, reunioesCard: IReuniao[], loading: boolean) => {
  return <Grid item>
    <Paper elevation={2} sx={{ borderRadius: '10px', width: '295px' }}>
      <GridCenter container sx={{ padding: '10px', height: '230px' }}>
        <GridCenter item xs={3}>
          <Icon sx={{ fontSize: '50px' }} />
        </GridCenter>
        <Grid item xs={9}>
          <Typography variant='subtitle2'>{titulo}</Typography>
        </Grid>

        {loading ?
          <GridCenter item>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </GridCenter>
          : <>
            <Grid item xs={12} sx={{ minHeight: '138px' }}>
              {tableCard(reunioesCard)}
            </Grid>
            <Grid item xs={12}>
              <LinkStato to={'/'}>Ver mais</LinkStato>
            </Grid>
          </>
        }
      </GridCenter>
    </Paper>
  </Grid>;
}

export const GridCenter = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: flex-start;
`;

const LinkStato = styled(Link)`
  text-decoration: none;
  margin-left: 15px;
  margin-top: 10px !important ;
  color: #118BD7;
`;
