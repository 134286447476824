import { Box, createTheme, Drawer, FormControl, Grid, Icon, InputLabel, MenuItem, ThemeProvider, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridEventListener, ptBR } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ButtonStato } from "../../../../../commons/styleds/buttons";
import { GridItemCadastroBtnSalvar } from "../../../../../commons/styleds/grids";
import { IconDrawer } from "../../../../../commons/styleds/icons";
import { SelectStato } from "../../../../../commons/styleds/selects";
import { IIdioma } from "../../../../../commons/types/config";
import { IComanda, ICv } from "../../../../../commons/types/crm";
import InputArquivo from "../../../../../components/InputArquivo/InputArquivo";
import { useIdiomaConfigActions } from "../../../../../states/config/idiomas/idioma.actions";
import { useCvActions } from "../../../../../states/contatos/auxiliares/cvs/cv.actions";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilState, useRecoilValue } from "recoil";
import { assessoradoAtom } from "../../../../../states/comandas/AssessoradoState";
import { comandaAtom } from "../../../../../states/comandas/ComandaState";
import { useParams } from "react-router-dom";
import { IParams } from "../../ProgramaAtual";
import { useComandaActions } from "../../../../../states/comandas/comanda.actions";

const empty: ICv = {
  id: 0,
  nome: '',
  idioma: '',
};

const Curriculos = () => {
  const { id } = useParams<IParams>();
  const [comandaState, setComandaState] = useRecoilState(comandaAtom);
  const comandaActions = useComandaActions();
  const cvsActions = useCvActions();
  const [open, setOpen] = useState<boolean>(false);

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);

  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [contatoId, setContatoId] = useState<number | undefined>(undefined);

  const [cvs, setCVs] = useState<ICv[]>([]);
  const [cv, setCV] = useState<ICv>();
  const [selectedCVs, setSelectedCVs] = useState<ICv[] | undefined>(undefined);
  const [idioma, setIdioma] = useState<IIdioma>({} as IIdioma);

  const [editAcao, setEditAcao] = useState<boolean>(false);
  const [acaoEmEdicao, setAcaoEmEdicao] = useState<ICv>(empty);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [acaoDeleteId, setAcaoDeleteId] = useState<number>(0);
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [arquivoUrl, setArquivoUrl] = useState('');

  const [idiomasSelect, setIdiomasSelect] = useState<IIdioma[]>([]);
  const idiomaConfigActions = useIdiomaConfigActions();

  const columns: GridColDef[] = [
    {
      field: 'nomeCv',
      headerName: 'Nome',
      width: 190,
    },
    {
      field: 'idioma',
      headerName: 'Idioma',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ICv;
        return idiomasSelect?.find((f) => f.id === obj.idIdioma)?.nome;
      },
    },
    {
      field: 'dtCadastro',
      headerName: 'Dt Cadastro',
      width: 150,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ICv;
        return (
          <a href={obj.anexo!} target='_blank'>
            <Icon
              sx={{ cursor: 'pointer' }}
            >
              {' '}
              <DownloadIcon />{' '}
            </Icon>

          </a>
        );
      },
    },
  ];

  useEffect(() => {
    idiomaConfigActions
      .list()
      .then((resp: IIdioma[]) => setIdiomasSelect(resp))
      .catch((err: any) => toast.warn(err));

    const comanda = comandaState.find((f) => f.id === Number(id));
    if (comanda) {
      getCvs(comanda.contato!.id);
      setContatoId(comanda.contato!.id);
    }
    else {
      carregarComanda()
        .then((comanda: IComanda) => getCvs(comanda.contato!.id));
    }
  }, [id]);

  const getCvs = (contatoId: number) => {
    cvsActions
      .list(false, `?contatoId=${contatoId}`)
      .then((resp: ICv[]) => setCVs(resp))
      .catch((err: any) => toast.warn(err))
      .finally(() => setLoading(false));
  }

  const carregarComanda = async () => {
    const comandaGet = await comandaActions.get(Number(id));
    setContatoId(comandaGet.contato!.id);
    return comandaGet;
  }

  const openDrawerCV = (result: any) => {
    setCV(empty);
    setOpen(result);
  };

  const HandleApagar = (id: any) => {
    const sub = cvs.filter((f) => f.id !== id);
    setCVs(sub);
  };

  const AdicionarNaLista = () => {
    cv!.nome = nomeArquivo;
    cv!.idioma = idioma.nome;
    cv!.idIdioma = idioma.id;
    cv!.nomeCv = nomeArquivo;
    cv!.idContato = contatoId;

    cvsActions.createFormData(cv!).then(data => {
      const newList = cvs.concat(cv!);
      setCVs(newList);
      setIdioma({} as IIdioma);
      setArquivoUrl("");
      setNomeArquivo("");
      openDrawerCV(false);
      toast.success('CV adicionado com sucesso');
    })
    setLoadingSave(false);
  };

  const HandleSetIdioma = (idioma: any) => {
    let id = idioma.target.value;
    let idiomaI = idiomasSelect.find((f) => f.id == id);
    setIdioma(idiomaI!);
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => row?.id}
              rows={cvs ?? []}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '14px',
              }}
            />
          </ThemeProvider>

          <GridItemCadastroBtnSalvar
            item
            xs={11}
            md={11}
            sx={{
              position: 'relative',
              bottom: '34%',
              right: '6%',
              zIndex: '30',
              left: '8%',
            }}
          >
            <ButtonStato
              style={{
                padding: '0px',
                height: '35px',
                minWidth: '35px',
              }}
              onClick={() => openDrawerCV(true)}
              variant='contained'
            >
              +
            </ButtonStato>
          </GridItemCadastroBtnSalvar>

          <Drawer
            style={{ minWidth: '250px' }}
            anchor={'right'}
            open={open}
            onClose={() => openDrawerCV(false)}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerCV(false)}>
                    close
                  </IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant='h5'>
                    {'Adicionar CV na lista'}
                  </Typography>
                </Grid>
              </Grid>
              <FormControl required fullWidth variant='outlined' sx={{ marginTop: "20px" }}>
                <InputLabel id='demo-simple-select-label'>
                  Idioma
                </InputLabel>
                <SelectStato onChange={HandleSetIdioma} required label='Consultor'>
                  {idiomasSelect &&
                    idiomasSelect!.map(
                      (grau: IIdioma, index: number) => (
                        <MenuItem value={grau.id} key={index}>
                          {grau.nome}
                        </MenuItem>
                      )
                    )}
                </SelectStato>
              </FormControl>
              <InputArquivo
                isEdit={editAcao}
                objOnEdit={acaoEmEdicao}
                setObjOnEdit={setAcaoEmEdicao}
                newObj={cv}
                setNewObj={setCV}
                nameFile={nomeArquivo}
                setNameFile={setNomeArquivo}
                urlFile={arquivoUrl}
                setUrlFile={setArquivoUrl}
              />

              <GridItemCadastroBtnSalvar
                item
                xs={11}
                md={11}
                sx={{
                  position: 'fixed',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <ButtonStato variant='contained' onClick={AdicionarNaLista}>
                  Adicionar
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          </Drawer>
        </Box>
      </Grid>
    </>
  );
}

export default Curriculos;
