import { useState } from 'react';
import { PaperReunioes } from '../../Reunioes';
import { Box, Tab, Tabs } from '@mui/material';
import {
  CustomTabPanel,
  a11yProps,
} from '../../../components/Tabs/accordionAndTabPanel';
import ProgramaAtual from './ProgramaAtual';
import MercadoAlvoCrm from './MercadoAlvo';
import Anexo from './Anexo';

const AssessoradoDetalhes: React.FC = () => {
  const [value, setValue] = useState<number>(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <PaperReunioes style={{ paddingBottom: '60px' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              margin: '0px 25px !important',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="tabs assessorado detalhes"
            >
              <Tab label="Programa atual" {...a11yProps(0)} />
              <Tab label="Mercado Alvo" {...a11yProps(1)} />
              <Tab label="Perfil" {...a11yProps(2)} />
              <Tab label="Anexo" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ProgramaAtual />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MercadoAlvoCrm />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            Perfil
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Anexo />
          </CustomTabPanel>
        </Box>
      </PaperReunioes>
    </>
  );
};

export default AssessoradoDetalhes;
