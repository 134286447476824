import { Route, Switch } from "react-router-dom";
import Pagina404 from './pages/Pagina404';
import MenuBar from './components/Menu';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Reunioes from './pages/Reunioes';
import Assessorados from './pages/Assessorados/Index';
import Configuracoes from './pages/Configuracoes';
import AssessoradoDetalhes from './pages/Assessorados/Detalhes';


function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <MenuBar>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/reunioes" component={Reunioes} />
        
        <Route exact path="/assessorados" component={Assessorados} />
        <Route exact path="/assessorados/:id" component={AssessoradoDetalhes} />

        <Route exact path="/configuracoes" component={Configuracoes} />
      </MenuBar>
      <Route exact component={Pagina404} />
    </Switch>
  );
}

export default Routes;
