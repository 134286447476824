import { useBaseRootActions } from '../../baseRoot.actions';
import { configAreaAtom } from './ConfigAreaState';

function useConfigAreaActions() {
  const baseActions = useBaseRootActions('configAreas', configAreaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useConfigAreaActions };
