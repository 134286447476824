import { useBaseRootActions } from '../../baseRoot.actions';
import { nivelDeCargoAtom } from './NivelDeCargoState';

function useNivelDeCargoActions() {
  const baseActions = useBaseRootActions('configNiveisCargo', nivelDeCargoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useNivelDeCargoActions };
