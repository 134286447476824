import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { styled } from 'styled-components';
import { InputLabel } from '@mui/material';
import { IdDescricao, IIdNome } from '../../commons/types/base';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(opcao: number, opcoes: readonly IdDescricao[], theme: Theme) {
  return {
    fontWeight:
      opcoes?.map(o => o.id)?.indexOf(opcao) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface Props {
  setValor: Function;
  valor: any[];
  opcoes: IIdNome[];
  label: string;
}

const MultiSelectNome: React.FC<Props> = (props: Props) => {
  const { setValor, valor, opcoes, label } = props;
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof valor>) => {
    const {
      target: { value },
    } = event;
    setValor(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (<>
    <InputLabel htmlFor='select-multiple'>{label}</InputLabel>
    <Select
      labelId="multi-select"
      multiple
      label={label}
      value={valor || []}
      onChange={handleChange}
      input={
        <OutlinedInputStato
          id="select-multiple"
          label={label}
          aria-describedby="base-name-helper-text"
          inputProps={{
            'aria-label': 'weight',
          }}
        />
      }
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value: any) => (
            <Chip key={value} label={opcoes.filter(opcao => opcao.id == value)[0].nome} />
          ))}
        </Box>
      )}
      MenuProps={MenuProps}
    >
      {opcoes.map((opcao: IIdNome) => (
        <MenuItem
          key={opcao.id}
          value={opcao.id}
          style={getStyles(opcao.id!, valor, theme)}
        >
          {opcao.nome}
        </MenuItem>
      ))}
    </Select>
  </>);
}

const OutlinedInputStato = styled(OutlinedInput)`
  border-radius: 19px !important;
`;

export default MultiSelectNome;

