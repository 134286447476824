import { useBaseRootActions } from "../../../baseRoot.actions";
import { cvAtom } from "./CvState";

function useCvActions() {
  const baseActions = useBaseRootActions('curriculos', cvAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
    createFormData: baseActions.createFormData,
  };
}

export { useCvActions };

