import { useBaseActions } from '../base.actions';
import { comandaAtom } from "./ComandaState";

function useComandaActions() {
  const baseActions = useBaseActions('comandas', comandaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    patch: baseActions.patch,
    // create: baseActions.create,
    // update: baseActions.update,
    // delete: baseActions.delete,
  };
}

export { useComandaActions };
