import { useAxiosWrapper } from "../../services/axiosWrapper";
import { useBaseActions } from "../base.actions";
import { mercadoAlvoAtom } from "./MercadoAlvoState";


function useMercadoAlvoActions() {
  const baseActions = useBaseActions('mercadoAlvos', mercadoAlvoAtom);
  const axiosWrapper = useAxiosWrapper();

  return {
    get: baseActions.get,
    list: baseActions.list,
    create: baseActions.create,
    update: baseActions.update
  }
}

export { useMercadoAlvoActions };

