import { Typography } from '@mui/material';

const Configuracoes = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant='h2'>Em Construção</Typography>
    </div>
  );
};

export default Configuracoes;
