import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { IReuniao, StatusReuniaoEnum } from '../../commons/types/crm';
import moment from 'moment';

export const tableCard = (reunioesCard: IReuniao[]) => (
  <Table aria-label='tabela simples'>
    <TableBody>
      {reunioesCard?.length === 0 && (
        <TableRow>
          <TableCellReunioes component='th' scope='row'>
            <Typography variant='body1'>Nenhum dado a ser exibido.</Typography>
          </TableCellReunioes>
        </TableRow>
      )}
      {reunioesCard.slice(0, 3).map((reuniao, index) => (
        // TODO: tratar os dados de mostrar na tabela
        <TableRow key={index}>
          <TableCellReunioes component='th' scope='row'>
            {reuniao.nomeAssessorado.split(' ')[0]}
          </TableCellReunioes>
          <TableCellReunioes align='right'>
            {reuniao.status === StatusReuniaoEnum.PENDENTE_APROVACAO ||
              reuniao.status === StatusReuniaoEnum.APROVADA
              ? moment(reuniao.dtRealizadaInicio).format('DD/MM')
              : moment(reuniao.dtPrevista).format('DD/MM')}
          </TableCellReunioes>
          <TableCellReunioes align='right'>
            {moment(reuniao.dtRealizadaInicio).format('hh:mm')}
          </TableCellReunioes>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const TableCellReunioes = styled(TableCell)`
  padding: 8px 16px !important;
  font-size: 16px !important;
`;
