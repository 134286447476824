import { useBaseActions } from '../base.actions';
import { reunioesAtom } from './ReuniaoState';

function useReuniaoActions() {
  const baseActions = useBaseActions('reunioes', reunioesAtom, false);

  return {
    list: baseActions.list,
    update: baseActions.update,
    create: baseActions.create,
    patch: baseActions.patch,
  };
}

export { useReuniaoActions };
