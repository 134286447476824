import { Accordion, Box, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ICv } from "../../../../commons/types/crm";
import { a11yProps, AccordionDetails, AccordionSummary, CustomTabPanel } from "../../../../components/Tabs/accordionAndTabPanel";
import { useCvActions } from "../../../../states/contatos/auxiliares/cvs/cv.actions";
import Curriculos from "./curriculos";


const Anexo: React.FC = () => {

  useEffect(() => { }, [])

  return (
    <Accordion defaultExpanded style={{ borderRadius: '10px' }}>
      <AccordionSummary
        aria-controls='cvs-content'
        id='cvs-header'
      >
        <Typography>CVs</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Curriculos />
      </AccordionDetails>
    </Accordion>
  )
}

export default Anexo;

