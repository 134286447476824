import { useSetRecoilState } from 'recoil';
import { authAtom } from './AuthState';
import { useAxiosWrapper } from '../../services/axiosWrapper';
import { history } from '../../helpers/history';
import { useBaseActions } from '../base.actions';

function useUsuarioActions() {
  const baseActions = useBaseActions('', authAtom, true);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/consultores`;
  const axiosWrapper = useAxiosWrapper();
  const setAuth = useSetRecoilState(authAtom);

  return {
    login,
    logout,
    update: baseActions.update,
    get: baseActions.get,
    list: baseActions.list,
  };

  async function login(usuario: string, senha: string) {
    try {
      const user = await axiosWrapper.post(`${baseUrl}/autenticacao`, {
        usuario,
        senha,
      });
      localStorage.setItem('usuario', JSON.stringify(user));
      setAuth(user);
    } catch (e: any) {}

    const { from } = (history?.location?.state as any) || {
      from: { pathname: '/login' },
    };
    history.push(from);
  }

  function logout() {
    localStorage.removeItem('usuario');
    setAuth(null);

    history.push('/login');
  }
}

export { useUsuarioActions };
