import { useBaseRootActions } from '../../baseRoot.actions';
import { configSubAreaAtom } from './ConfigSubAreaState';

function useConfigSubAreaActions() {
  const baseActions = useBaseRootActions('configSubAreas', configSubAreaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useConfigSubAreaActions };
