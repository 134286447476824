import { useBaseRootActions } from '../../baseRoot.actions';
import { statusAtom } from '../status/StatusState';

function useStatusActions() {
  const baseActions = useBaseRootActions('status', statusAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useStatusActions };

