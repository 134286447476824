import { useBaseRootActions } from '../../baseRoot.actions';
import { faixaSalarialAtom } from './FaixaSalarialState';

function useFaixaSalarialActions() {
  const baseActions = useBaseRootActions('ConfigFaixasSalariais', faixaSalarialAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useFaixaSalarialActions };
