import { useAxiosWrapper } from "../../services/axiosWrapper";
import { useBaseActions } from "../base.actions";
import { usuarioOneAtom } from "./UsuarioOneState";

function useUsuarioOneActions() {
  const baseActions = useBaseActions('usuariosOne', usuarioOneAtom);
  const axiosWrapper = useAxiosWrapper();

  return {
    list: baseActions.list,
    get: baseActions.get,
  };
}

export { useUsuarioOneActions };
